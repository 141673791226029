<template>
  <b-card>
    <div class="d-flex">
      <b-form-input
        id="filter-input"
        v-model="filter"
        type="search"
        class="col-md-4 col-xl-2"
        placeholder="Search Contact"
      ></b-form-input>
      <b-button class="mb-3 ml-auto" variant="success" to="/form-layouts/add-course"
        >+ New Course</b-button
      >
    </div>
    <b-table
      hover
      bordered
      :filter="filter"
      responsive
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      class="contact-table"
    >
      <!-- A custom formatted column -->
      <template #cell(status)="data" width="100" class="text-center">
        <b-badge v-if="data.item.status === 1" class="mr-1 px-2" pill variant="success">Complete</b-badge>
        <b-badge v-if="data.item.status === 0" class="mr-1 px-2" pill variant="warning">In Progress</b-badge>
        <b-badge v-if="data.item.status === 2" class="mr-1 px-2" pill variant="warning">Open for registration</b-badge>
        <b-badge v-if="data.item.status === 3" class="mr-1 px-2" pill variant="danger">Expired</b-badge>
      </template>
      <template #cell(Actions)="data" width="100" class="text-center">
        <a class="mx-1 cursor-pointer" @click="viewItem(data.item)"
          ><feather type="eye" class="feather-sm"></feather
        ></a>
        <a class="mx-1 cursor-pointer" @click="addStudent(data.item)"
          ><feather type="plus" class="feather-sm"></feather
        ></a>
        <!--<a
          class="mx-1 cursor-pointer text-danger"
          @click="editItem(data.item)"
          ><feather type="edit" class="text-danger feather-sm"></feather
        ></a>
        <a
          class="mx-1 cursor-pointer text-danger"
          @click="deleteItem(data.item)"
          ><feather type="trash-2" class="text-danger feather-sm"></feather
        ></a>-->
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="center"
    ></b-pagination>
    <!-- ====================================== -->
    <!-- Edit Contact box -->
    <!-- ====================================== -->
    <b-modal
      ref="edit-modal"
      id="editModal"
      hide-header
      @ok="save"
      @cancel="close"
      ok-title="Save"
    >
      <h4 class="mb-4">{{ formTitle }}</h4>
      <b-row>
        <b-col cols="12" lg="12" class="mb-3">
          <h6>Profile Pic</h6>
          <b-img
            v-if="editedItem.avatar"
            :src="editedItem.avatar"
            width="37"
            rounded="circle"
          />
          <b-form-file
            @change="onFileChange"
            placeholder="Choose a file..."
          ></b-form-file>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Id</h6>
          <b-form-input
            v-model="editedItem.id"
            placeholder="Id"
            name="name-group"
          ></b-form-input>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Name</h6>
          <b-form-input
            v-model="editedItem.name"
            placeholder="Enter your name"
            name="name-group"
          ></b-form-input>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Email</h6>
          <b-form-input
            v-model="editedItem.usermail"
            placeholder="Enter your email"
            name="name-group"
          ></b-form-input>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Phone</h6>
          <b-form-input
            v-model="editedItem.phone"
            placeholder="Enter your Phone"
            name="name-group"
          ></b-form-input>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Date of joining</h6>
          <b-form-datepicker
            id="example-datepicker"
            v-model="editedItem.jdate"
            :date-format-options="{
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            }"
            class="mb-2"
          ></b-form-datepicker>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Role</h6>
          <b-form-input
            v-model="editedItem.role"
            placeholder="Enter your name"
            name="name-group"
          ></b-form-input>
        </b-col>
      </b-row>
    </b-modal>
    <!-- ====================================== -->
    <!-- Edit Contact box -->
    <!-- ====================================== -->
    <b-modal
      ref="view-modal"
      id="viewModal"
      hide-header
      @cancel="close"
    >
      <h4 class="mb-4">View Course Details</h4>
      <b-row>
        <b-col cols="12" lg="12" class="mb-3">
          <b-list-group>
            <b-list-group-item v-for="key in viewedItem" :key="key">{{ key }}: {{viewedItem[key]}}</b-list-group-item>
          </b-list-group>
        </b-col>  
      </b-row>
    </b-modal>
    <!-- ====================================== -->
    <!-- Add Student box -->
    <!-- ====================================== -->
    <b-modal
      v-if="selectedCourse"
      ref="add-student-modal"
      id="addStudentModal"
      hide-header
      @ok="addStudentToCourse"
      @cancel="close"
      ok-title="Save"
    >
      <h4 class="mb-4">{{ formTitle }}</h4>
      <b-row>
        <b-col cols="12" lg="12" class="mb-3">
          <h6>Title</h6>
          <p>{{ selectedCourse.title }}</p>
        </b-col>
        <b-col cols="12" lg="12" class="mb-3">
          <h6>Teacher</h6>
          <p>{{ selectedCourse.teacher }}</p>
        </b-col>
        <b-col cols="12" lg="12" class="mb-3">
          <h6>Fee</h6>
          <p>{{ selectedCourse.fee }}</p>
        </b-col>
        <b-col cols="12" lg="12" class="mb-3">
          <h6>Assign Student</h6>
          <b-form-select v-model="regForm.user_id" :options="students"></b-form-select>
        </b-col>
        <b-col cols="12" lg="12" class="mb-3">
          <h6>Payment Type</h6>
          <b-form-select v-model="regForm.payment_type" :options="paymentTypes"></b-form-select>
        </b-col>
        <b-col cols="12" lg="12" class="mb-3">
          <h6>Payment Status</h6>
          <b-form-select v-model="regForm.payment_status" :options="paymentStatuses"></b-form-select>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "CoursesTable",
  data: () => ({
    sortBy: "course_id",
    fields: [
      { key: "course_id", sortable: true },
      "title",
      "status",
      "fee",
      "created_on",
      "teacher",
      "start_date",
      "end_date",
      "Actions",
    ],
    items: null,
    students: null,
    filter: null,
    totalRows: 1,
    currentPage: 1,
    perPage: 5,
    url: null,
    editedIndex: -1,
    editedItem: {
      id: "",
      avatar: "",
      userinfo: "",
      usermail: "",
      phone: "",
      jdate: "",
      role: "",
    },
    defaultItem: {
      id: "",
      avatar: "",
      userinfo: "",
      usermail: "",
      phone: "",
      jdate: "",
      role: "",
    },
    viewedItem: null,
    selectedCourse: null,
    paymentTypes: [
      { text: "Credit Card", value: "card" },
      { text: "Bank Transfer", value: "bank" },
      { text: "Manual", value: "cash" }
    ],
    paymentStatuses: [
      { text: "Pending", value: 0 },
      { text: "Approved", value: 1 }
    ],
    regForm:{
      user_id: null,
      course_id: null,
      fee: 0,
      payment_type: null,
      payment_status: null
    }
  }),
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(["getUsers", "getCourses", "assignStudent", "savePayment"]),
    initialize() {
      this.getCourses().then(res => {
        this.items = res;
      })
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$refs["edit-modal"].show();
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.items.splice(index, 1);
    },
    viewItem(item) {
      this.viewedItem = item;
      this.$refs["view-modal"].show();
    },
    close() {
      this.$refs["edit-modal"].hide();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
      } else {
        this.items.push(this.editedItem);
      }
      this.close();
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.editedItem.avatar = URL.createObjectURL(file);
    },
    addStudent(course){
      this.getUsers().then(res => {
        this.students = res.map(u => { 
          return {
            ...u, 
            text: JSON.parse(u.profile).first_name+" "+JSON.parse(u.profile).last_name,
            value: u.user_id
          } 
        });
        this.selectedCourse = course;
        this.$refs["add-student-modal"].show();
      })
    },
    addStudentToCourse(){
      //let user = this.getUser(this.regForm.user_id);
      this.regForm.course_id = this.selectedCourse.course_id;
      //this.regForm.student = JSON.parse(user.profile).first_name+" "+JSON.parse(user.profile).last_name;
      //this.regForm.student = this.selectedCourse.course_id;
      //this.regForm.teacher = this.selectedCourse.teacher;
      this.regForm.fee = this.selectedCourse.fee;
      this.assignStudent(this.regForm).then((res) => {
        if(this.regForm.payment_status === 1){
          const payment = {
            user_id: 0,
            course_id: 0,
            reg_id: res.id,
            amount: res.fee,
            status: this.regForm.payment_status
          }
          this.savePayment(payment).then(() => {
            this.$bvToast.toast("Student Added to the Course", {
              title: 'Successfull Operation',
              autoHideDelay: 5000
            })
          })
        }
        else{
          this.$bvToast.toast("Student Added to the Course", {
            title: 'Successfull Operation',
            autoHideDelay: 5000
          })
        }
      })
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Contact" : "Edit Contact";
    },
    rows() {
      return this.items.length;
    },
  },
  watch: {},
};
</script>

<style>
.contact-table td {
  vertical-align: middle;
}
</style>